 @tailwind base;
 @tailwind components;
 @tailwind utilities;

 *,
 *:before,
 *:after {
     padding: 0;
     margin: 0;
     box-sizing: border-box;
 }

 .form{
    backdrop-filter: blur(12px) saturate(180%);
 }


